var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass},[_c('page-header',{staticClass:"mb-32pt",attrs:{"title":"Make a hire","container-class":null}}),_c('page-separator',{attrs:{"title":""}}),_c('div',{staticClass:"mx-3 mb-4"},[_c('span',[_vm._v(" Complete the form below to hire candidates from your partner schools. A reference fee "),(_vm.referenceFee !== null)?_c('span',[_vm._v("of "),_c('b',[_vm._v("$"+_vm._s(_vm.$n(_vm.referenceFee))+" per hire ")])]):_vm._e(),_vm._v(" will be charged to "),(_vm.isPayMethodLoading)?_c('i',[_vm._v("Loading...")]):(_vm.paymentMethod)?_c('b',[_vm._v("card ending with "+_vm._s(_vm.get(_vm.paymentMethod, 'last_four')))]):_c('a',{staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addPaymentMethodClick.apply(null, arguments)}}},[_vm._v("Add payment")]),_vm._v(". ")])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"School","label-for":"schools","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.school.required && _vm.$v.school.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"school","label":"name","reduce":function (sc) { return sc.id; },"placeholder":"Select Schools","options":_vm.allSchools,"loading":_vm.areSchoolsLoading,"disabled":_vm.isLoading || _vm.areSchoolsLoading},on:{"input":_vm.schoolChanged},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg text-primary"})],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.school.required && _vm.$v.school.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Candidates","label-for":"candidates","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.candidates.required && _vm.$v.candidates.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"candidates","filter-by":_vm.filterCandidates,"label":"user","multiple":"","reduce":function (candidate) { return candidate.id; },"placeholder":"Select Candidates","options":_vm.allCandidates,"loading":_vm.areCandidatesLoading,"disabled":_vm.isLoading || _vm.areCandidatesLoading},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.user.avatar_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.user.avatar_url,"width":"20","alt":"Logo"}}):_c('md-icon',{staticClass:"text-primary"},[_vm._v("person")])],1),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(option.user.first_name)+" "+_vm._s(option.user.last_name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.user.first_name)+" "+_vm._s(option.user.last_name)+" ")]}}]),model:{value:(_vm.candidates),callback:function ($$v) {_vm.candidates=$$v},expression:"candidates"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.candidates.required && _vm.$v.candidates.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Job","label-for":"job","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.job.required && _vm.$v.job.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"job","label":"title","reduce":function (sc) { return sc.id; },"placeholder":"Select Job","options":_vm.allJobs,"loading":_vm.areJobsLoading,"disabled":_vm.isLoading || _vm.areJobsLoading},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.required && _vm.$v.job.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Pay Rate","label-for":"pay_rate","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.pay_rate.required && _vm.$v.pay_rate.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"pay_rate","label":"text","reduce":function (sc) { return sc.value; },"placeholder":"Select Pay Rate","options":_vm.allPayRates,"disabled":_vm.isFormLoading},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.pay_rate),callback:function ($$v) {_vm.pay_rate=$$v},expression:"pay_rate"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.pay_rate.required && _vm.$v.pay_rate.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Salary","label-for":"salary","label-class":"form-label"}},[_c('currency-input',{staticClass:"form-control",class:!_vm.$v.salary.required && _vm.$v.salary.$dirty ? 'is-invalid' : '',attrs:{"placeholder":"Enter salary","allow-negative":false,"disabled":_vm.isFormLoading},model:{value:(_vm.salary),callback:function ($$v) {_vm.salary=$$v},expression:"salary"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.salary.required && _vm.$v.salary.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Hiring Date","label-for":"hiring_date","label-class":"form-label"}},[_c('date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"DD MMMM, YYYY","valueType":"date","id":"hiring_date","lang":"en","placeholder":"Enter Hiring Date","state":!_vm.$v.hiring_date.required && _vm.$v.hiring_date.$dirty ? false : null,"input-class":("mx-input " + (!_vm.$v.hiring_date.required && _vm.$v.hiring_date.$dirty ? 'form-control is-invalid' : '')),"disabled":_vm.isFormLoading},model:{value:(_vm.hiring_date),callback:function ($$v) {_vm.hiring_date=$$v},expression:"hiring_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.hiring_date.required && _vm.$v.hiring_date.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-btn',{staticClass:"btn-normal",staticStyle:{"width":"150px"},attrs:{"variant":"primary","disabled":_vm.isFormLoading,"type":"submit"}},[(_vm.isLoading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('span',[_vm._v("Make a hire")])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }